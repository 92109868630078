import React, {useState, useContext} from 'react'
import { SideBarSegment } from './SideBarSegment'
import {TrafficSignImg} from './TrafficSignImg'
import OptionsContext from './OptionsContext'
import { ACTIONS } from './Reducer'

const SYKLI_LOGO = process.env.PUBLIC_URL + '/Logot LIKSUun.png'

const SideBar = () => {
    const [open, setOpen] = useState('')
    const {state, dispatch} = useContext(OptionsContext)

    const setMarkerSize = (e) => {
        dispatch({type: ACTIONS.SET_MARKER_SIZE, payload: e.target.value})
    }

    const setLineColor = (e) => {
        dispatch({type: ACTIONS.SET_LINE_COLOR, payload: e.target.value})
    }

    const setLineOpacity = (e) => {
        dispatch({type: ACTIONS.SET_LINE_OPACITY, payload: e.target.value})
    }

    const setAreaOpacity = (e) => {
        dispatch({type: ACTIONS.SET_AREA_OPACITY, payload: e.target.value})
    }

    const setLineWeight = (e) => {
        dispatch({type: ACTIONS.SET_LINE_WEIGHT, payload: e.target.value})
    }

    const setPostSize = (e) => {
        dispatch({type: ACTIONS.SET_POST_SIZE, payload: e.target.value})
    }

    const setToLine = () => {
        dispatch({type: ACTIONS.SET_NEXT_ACTION, payload: ACTIONS.DRAW_LINE})
    }

    const setToArea = () => {
        dispatch({type: ACTIONS.SET_NEXT_ACTION, payload: ACTIONS.DRAW_AREA})
    }

    const setToText = () => {
        dispatch({type: ACTIONS.SET_NEXT_ACTION, payload: ACTIONS.DRAW_TEXT})
    }

    const setAreaColor = (e) => {
        dispatch({type: ACTIONS.SET_AREA_COLOR, payload: e.target.value})
    }

    const setTextColor = (e) => {
        dispatch({type: ACTIONS.SET_TEXT_COLOR, payload: e.target.value})
    }

    const setTextOpacity = (e) => {
        dispatch({type: ACTIONS.SET_TEXT_OPACITY, payload: e.target.value})
    }

    const setTextSize = (e) => {
        dispatch({type: ACTIONS.SET_TEXT_SIZE, payload: e.target.value})
    }


    return <div id={'sidebar'}>
        <div id={'logo'}>
            <img src={SYKLI_LOGO} alt={'sykli logo'}></img>
        </div>
        <SideBarSegment open={open} setOpen={setOpen} value={'A'} name={'Varoitusmerkit'}>
            <TrafficSignImg src={'A4'}/>
            <TrafficSignImg src={'A9'}/>
            <TrafficSignImg src={'A11'}/>
            <TrafficSignImg src={'A21'}/>
            <TrafficSignImg src={'A22_3'}/>
            <TrafficSignImg src={'A23'}/>
            <TrafficSignImg src={'A24'}/>
        </SideBarSegment>
        <SideBarSegment open={open} setOpen={setOpen} value={'B'} name={'Etuajo-oikeus ja väistämismerkit'}>
            <TrafficSignImg src={'B3'}/>
            <TrafficSignImg src={'B4'}/>
            <TrafficSignImg src={'B5'}/>
            <TrafficSignImg src={'B6'}/>
            <TrafficSignImg src={'B7'}/>
        </SideBarSegment>
        <SideBarSegment open={open} setOpen={setOpen} value={'C'} name={'Kielto- ja rajoitusmerkit'}>
            <TrafficSignImg src={'C1'}/>
            <TrafficSignImg src={'C2'}/>
            <TrafficSignImg src={'C11'}/>
            <TrafficSignImg src={'C13'}/>
            <TrafficSignImg src={'C14'}/>
            <TrafficSignImg src={'C17'}/>
            <TrafficSignImg src={'C18'}/>
            <TrafficSignImg src={'C19'}/>
            <TrafficSignImg src={'C32'}/>
            <TrafficSignImg src={'C32_40'}/>
            <TrafficSignImg src={'C32_30'}/>
            <TrafficSignImg src={'C33'}/>
            <TrafficSignImg src={'C37'}/>
            <TrafficSignImg src={'C38'}/>
        </SideBarSegment>
        <SideBarSegment open={open} setOpen={setOpen} value={'D'} name={'Määräysmerkit'}>
            <TrafficSignImg src={'D3_1'}/>
            <TrafficSignImg src={'D3_2'}/>
        </SideBarSegment>
        <SideBarSegment open={open} setOpen={setOpen} value={'E'} name={'Sääntömerkit'}>
            <TrafficSignImg src={'E1'}/>
        </SideBarSegment>
        <SideBarSegment open={open} setOpen={setOpen} value={'F'} name={'Opastusmerkit'}>
            <TrafficSignImg src={'F5'}/>
            <TrafficSignImg src={'F6'}/>
            <TrafficSignImg src={'F15'}/>
            <TrafficSignImg src={'F24_1'}/>
        </SideBarSegment>
        <SideBarSegment open={open} setOpen={setOpen} value={'H'} name={'Lisäkilvet'}>
            <TrafficSignImg src={'H1'}/>
            <TrafficSignImg src={'H1_2'}/>
            <TrafficSignImg src={'H25'}/>
        </SideBarSegment>
        <SideBarSegment open={open} setOpen={setOpen} value={'I'} name={'Muut liikenteenohjausmerkit'}>
            <TrafficSignImg src={'I1'}/>
            <TrafficSignImg src={'I2_1'}/>
            <TrafficSignImg src={'I3_3'}/>
            <TrafficSignImg src={'I4'}/>
        </SideBarSegment>
        <br/>
        <SideBarSegment open={open} setOpen={setOpen} value={'options'} name={'Optiot'}>
            <div>
                <label htmlFor={'marker'}>Liikennemerkkkien koko</label>
                <span>{24}</span>
                <input id={'marker'} type='range' min={24} max={128} value={state.markerSize} onChange={setMarkerSize} ></input>
                <span>{128}: {state.markerSize}</span>
            </div>
            <div>
                <label htmlFor='postsize'>Tolpan koko</label>
                <span>1</span>
                <input id='postsize' type='range' min={1} max={128} step={1} value={state.postSize} onChange={setPostSize}></input>
                <span>128: {state.postSize}</span>
            </div>
            <hr/>
            <div>
                <label htmlFor={'color'}>Viivan Väri</label>
                <input id={'color'} type='color' value={state.lineColor} onChange={setLineColor}></input>
            </div>
            <div>
                <label htmlFor={'opacity'}>Viivan läpinäkyvyys</label>
                <span>{0}</span>
                <input id={'opacity'} type='range' min={0} max={1} step={0.01} value={state.lineOpacity} onChange={setLineOpacity}></input>
                <span>{1}: {state.lineOpacity}</span>
            </div>
            <div>
                <label htmlFor={'weight'}>Viivan paksuus</label>
                <span>{1}</span>
                <input id={'weight'} type='range' min={1} max={10} step={0.1} value={state.lineWeight} onChange={setLineWeight}></input>
                <span>{10}: {state.lineWeight}</span>
            </div>
            <hr/>
            <div>
                <label htmlFor='areacolor'>Alueen Väri</label>
                <input id='areacolor' type='color' value={state.areaColor} onChange={setAreaColor}></input>
            </div>
            <div>
                <label htmlFor={'areaopacity'}>Alueen läpinäkyvyys</label>
                <span>{0}</span>
                <input id={'areaopacity'} type='range' min={0} max={1} step={0.01} value={state.areaOpacity} onChange={setAreaOpacity}></input>
                <span>{1}: {state.areaOpacity}</span>
            </div>
            <hr/>
            <div>
                <label htmlFor='textcolor'>Tekstin väri</label>
                <input id='textcolor' type='color' value={state.textColor} onChange={setTextColor}></input>
            </div>
            <div>
                <label htmlFor={'textopacity'}>Alueen läpinäkyvyys</label>
                <span>{0}</span>
                <input id={'textopacity'} type='range' min={0} max={1} step={0.01} value={state.textOpacity} onChange={setTextOpacity}></input>
                <span>{1}: {state.textOpacity}</span>
            </div>
            <div>
                <label htmlFor='textsize'>Fontti koko</label>
                <span>{10}</span>
                <input id='textsize' type='range' min={10} max={60} value={state.textSize} onChange={setTextSize}></input>
                <span>{60}: {state.textSize}</span>
            </div>
        </SideBarSegment>
        <SideBarSegment open={open} setOpen={setOpen} value={'piirtotyokalut'} name={'Piirtotyökalut'}>
            <button onClick={setToLine}>Viiva</button>
            <button onClick={setToArea}>Alue</button>
            <button onClick={setToText}>Teksti</button>
        </SideBarSegment>
    </div>
}

export default SideBar