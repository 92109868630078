import React, {useContext} from 'react'
import OptionsContext from './OptionsContext'
import { ACTIONS } from './Reducer'
import { TrafficSignURLs } from './TrafficSignURLsContext'

export const TrafficSignImg = ({src}) => {
    const TrafficSigns = useContext(TrafficSignURLs)
    const {state, dispatch} = useContext(OptionsContext)

    const changeSelected = () => {
        if (src[0] === 'I') {
            dispatch({type: ACTIONS.SET_SELECTED_CONTROL_SIGNS, payload: src})
            dispatch({type: ACTIONS.SET_NEXT_ACTION, payload: ACTIONS.DRAW_CONTROL_LINE })
        } else {
            if (!state.selectedControlSigns) {
                if (state.selectedMarkers.length <= 2)
                    dispatch({ type: ACTIONS.ADD_SELECTED_MARKER, payload: src})
                if (state.nextAction === ACTIONS.NO_ACTION)
                    dispatch({ type: ACTIONS.SET_NEXT_ACTION, payload: ACTIONS.ORIGIN_POINT })
            }
        }

    }

    return (
        <img
            className={'traffic-img ' + (state.selectedMarkers.includes(src) && 'selected-img')}
            src={TrafficSigns[src.charAt(0)][src]}
            alt={TrafficSigns[src.charAt(0)][src].split('/').slice(-1)[0]}
            onClick={changeSelected}
        />
    )
}
