import React, { useState, useRef, useEffect } from 'react'
import { Map, GoogleApiWrapper } from 'google-maps-react';

/* 
https://www.npmjs.com/package/google-maps-react

alhaalla on paikka minne laitetaan googlen mapsin APi avain!!

google maps container
ottaa yhteyden googlemaps apiin ja piirtää sen näytölle

optioita mitä voi muokata
initialCenter: mihin kartta on keskittynyt silloin kun sivu avataan
zoom: alkuperäinen zoom taso
styles: voidaan muokata kartan tyyliä, tällä hetkellä poistetaan kaikki Points of interest (poi)
https://developers.google.com/maps/documentation/javascript/style-reference

*/
function MapContainer(props) {

	const [style, setStyle] = useState({
		width: window.innerWidth + 'px',
		height: window.innerHeight + 'px',
		right: 0,
		top: 0,
	})
	const mapRef = useRef(null)

	useEffect(() => {
		const handleResize = () => {
			setStyle({
				width: window.innerWidth + 'px',
				height: window.innerHeight + 'px',
				right: 0,
				top: 0,
			})
		}
		window.addEventListener('resize', handleResize)
		return () => { window.removeEventListener('resize', handleResize) }
	}, [])

	return (
		<Map
			ref={mapRef}
			styles={
				[{
					'featureType': 'poi',
					'stylers': [
						{ 'visibility': 'off' }
					]
				}]

			}
			containerStyle={style}
			google={props.google}
			zoom={14}
			initialCenter={{
				lat: 61.4978,
				lng: 23.7610
			}}
			disableDefaultUI={true}
			zoomControl={true}
			scaleControl={true}
			mapTypeControl={true}
			mapTypeControlOptions={{ position: props.google.maps.ControlPosition.TOP_RIGHT }}
			clickableIcons={false}
			draggable={true}
		>
		</Map>
	);
}

export default GoogleApiWrapper({
	apiKey: ('AIzaSyDUqboDBAGldkKZbThkZgLsp8QgKYD3Hyc')
})(MapContainer)