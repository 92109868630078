export const ACTIONS = {
    SET_SELECTED_MARKER: 'setselectedmarker',
    ADD_SELECTED_MARKER: 'addselectedmarker',
    REMOVE_SELECTED_MARKER: 'removeselectedmarker',

    SET_MARKER_SIZE: 'setmarkersize',
    SET_POST_SIZE: 'setpostsize',

    SET_NEXT_ACTION: 'nextaction',

    NO_ACTION: 'noaction',
    ORIGIN_POINT: 'originpoint',
    END_POINT: 'endpoint',
    ROTATION: 'rotation',
    FINALIZNG: 'finalizing',

    SET_LINE_COLOR: 'setlinecolor',
    SET_LINE_OPACITY: 'setlineopacity',
    SET_LINE_WEIGHT: 'setlineweight',
    DRAW_LINE: 'drawline',
    ADD_LINE: 'addline',
    DELETE_LINE: 'deleteline',
    
    SET_MAP_LOCK: 'setmaplock',

    DRAW_AREA: 'drawarea',
    ADD_AREA: 'addarea',
    DELETE_AREA: 'deletearea',
    SET_AREA_OPACITY: 'setareaopacity',
    SET_AREA_COLOR: 'setareacolor',

    SET_TEXT_COLOR: 'settextcolor',
    SET_TEXT_OPACITY: 'settextopacity',
    SET_TEXT_SIZE: 'settextsize',
    DRAW_TEXT: 'drawtext',
    ADD_TEXT: 'addtext',
    DELETE_TEXT: 'deletetext',

    SET_SIGNS: 'setsigns',
    DELETE_SIGN: 'deletesign',

    ADD_CONTROL_SIGNS: 'addcontrolsigns',
    REMOVE_CONTROL_SIGNS: 'removecontrolsigns',
    SET_SELECTED_CONTROL_SIGNS: 'setselectedCONTROL_SIGNS',
    DRAW_CONTROL_LINE: 'drawcontrolline',
    CONTROL_SIGN_POINT: 'controlsignpoint',
}

const Reducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_SELECTED_MARKER:
            return {...state, selectedMarkers: action.payload}
        case ACTIONS.ADD_SELECTED_MARKER:
            return {...state, selectedMarkers: [...state.selectedMarkers, action.payload]}
        case ACTIONS.REMOVE_SELECTED_MARKER:
            state.selectedMarkers.splice(action.payload, 1)
            return {...state}
        case ACTIONS.SET_MARKER_SIZE:
            return {...state, markerSize: action.payload}
        case ACTIONS.SET_NEXT_ACTION:
            return {...state, nextAction: action.payload}
        case ACTIONS.SET_LINE_COLOR:
            return {...state, lineColor: action.payload}
        case ACTIONS.SET_LINE_OPACITY:
            return {...state, lineOpacity: action.payload}
        case ACTIONS.SET_LINE_WEIGHT:
            return {...state, lineWeight: action.payload}
        case ACTIONS.SET_MAP_LOCK:
            return {...state, mapLock: !state.mapLock}
        case ACTIONS.SET_SIGNS:
            return {...state, signs: [...state.signs, action.payload]}
        case ACTIONS.DELETE_SIGN:
            state.signs.splice(action.payload, 1)
            return {...state}
        case ACTIONS.SET_POST_SIZE:
            return {...state, postSize: action.payload}
        case ACTIONS.ADD_LINE:
            return {...state, lines: [...state.lines, action.payload]}
        case ACTIONS.DELETE_LINE:
            state.lines.splice(action.payload, 1)
            return {...state}
        case ACTIONS.ADD_AREA:
            return {...state, areas: [...state.areas, action.payload]}
        case ACTIONS.DELETE_AREA:
            state.areas.splice(action.payload, 1)
            return {...state}
        case ACTIONS.SET_AREA_OPACITY:
            return {...state, areaOpacity: action.payload}
        case ACTIONS.SET_AREA_COLOR:
            return {...state, areaColor: action.payload}
        case ACTIONS.ADD_TEXT:
            return {...state, texts: [...state.texts, action.payload]}
        case ACTIONS.DELETE_TEXT:
            state.texts.splice(action.payload, 1)
            return {...state}
        case ACTIONS.SET_TEXT_COLOR:
            return {...state, textColor: action.payload}
        case ACTIONS.SET_TEXT_OPACITY:
            return {...state, textOpacity: action.payload}
        case ACTIONS.SET_TEXT_SIZE:
            return {...state, textSize: action.payload}
        case ACTIONS.ADD_CONTROL_SIGNS:
            return {...state, controlSigns: [...state.controlSigns, action.payload]}
        case ACTIONS.REMOVE_CONTROL_SIGNS:
            state.controlSigns.splice(action.payload, 1)
            return {...state}
        case ACTIONS.SET_SELECTED_CONTROL_SIGNS:
            return {...state, selectedControlSigns: action.payload}
        default:
            return state
    }
}

export default Reducer