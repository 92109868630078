import React, {useContext, useState, useEffect, useRef} from 'react'
import { TrafficSignURLs } from './TrafficSignURLsContext'
import OptionsContext from './OptionsContext'
import { ACTIONS } from './Reducer'

const TOLPAN_SUUNTA = process.env.PUBLIC_URL + '/TolpanSuunta.svg'
const LUKKO = process.env.PUBLIC_URL + '/padlock-silhouette.svg'
const LUKKO_AUKI = process.env.PUBLIC_URL + '/padlock-unlocked-silhou-01.svg'
/* 
sivun tärkeimmät ominaisuudet on tässä tiedostossa.

kontrolloi sivun piirtämis työkaluja.
*/
const Control = () => {
    /* 
    tempSigns, tempControlSigns, tempLine, tempArea ja tempText tilat on kai järkevää yhdistää yhdeksi tilaksi,
    mutta se vaatii aika paljon koodin muokkaamista
    */
    const TrafficSigns = useContext(TrafficSignURLs)
    const {state, dispatch} = useContext(OptionsContext)
    const [hideFinished, setHideFinished] = useState(false)
    /* referoi canvakseen (rivi 945) jotta voidaan piisrtää sen päälle järkevästi reactissa */
    const ref = useRef(null)
    const [tempSigns, setTempSigns] = useState({})
    /* Muut liikenneohjaus merkit eli I:llä alkavat liikennemerkit on mulla nimetty kontrolli merkiksi */
    const [tempControlSigns, setTempControlSigns] = useState({line: [], tempPoint: []})
    const [tempLine, setTempLine] = useState({line: [], tempPoint: []})
    const [tempArea, setTempArea] = useState({points: [], tempPoint: []})
    const [tempText, setTempText] = useState({text: ''})
    const [changeState, setChangeState] = useState('')

    /*
    callback funktio joka kutsutaan aina kun jotain statea muutetaan.

    piirtää kaikki viivat, alueet ja merkit silloin kun lukko on päällä.
    piirtäminen tapahtuu Canvas API: avulla.
    https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API
    */
    useEffect(() => {
        if (!state.mapLock) return
        ref.current.width = window.innerWidth
        ref.current.height = window.innerHeight
        let ctx = ref.current.getContext('2d')
        drawTempArea(ctx)
        drawPermAreas(ctx)
        drawTempLine(ctx)
        drawPermLines(ctx)
        ctx.globalAlpha = 1
        drawTempSigns(ctx)
        drawPermSigns(ctx)
        ctx.globalAlpha = 1
        drawTempText(ctx)
        drawPermText(ctx)

        drawTempControlSign(ctx)
        drawPermControlSigns(ctx)
    })

    /*
    piirtää kontrolli kerkit joita ollaan muokkaamassa
    */
    const drawTempControlSign = (ctx) => {
        if (tempControlSigns.line.length > 0) {
            if (state.nextAction === ACTIONS.DRAW_CONTROL_LINE) {
                ctx.strokeStyle = state.areaColor
                ctx.lineWidth = state.lineWeight
                ctx.beginPath()
                ctx.moveTo(tempControlSigns.line[0][0], tempControlSigns.line[0][1])
                for (let i = 1; i < tempControlSigns.line.length; i++) {
                    ctx.lineTo(tempControlSigns.line[i][0], tempControlSigns.line[i][1])
                }
                if (tempControlSigns.tempPoint.length > 0)
                    ctx.lineTo(tempControlSigns.tempPoint[0], tempControlSigns.tempPoint[1])
                ctx.globalAlpha = state.lineOpacity
                ctx.stroke()
            } else if (state.nextAction === ACTIONS.CONTROL_SIGN_POINT) {
                ctx.strokeStyle = state.areaColor
                ctx.lineWidth = state.lineWeight
                ctx.beginPath()
                ctx.moveTo(tempControlSigns.line[0][0], tempControlSigns.line[0][1])
                for(let i = 1; i < tempControlSigns.line.length; i++) {
                    ctx.lineTo(tempControlSigns.line[i][0], tempControlSigns.line[i][1])
                }
                ctx.globalAlpha = state.lineOpacity
                ctx.stroke()
            }

            if (tempControlSigns.pointer) {
                ctx.strokeStyle = state.lineColor
                ctx.lineWidth = state.lineWeight
                ctx.beginPath()
                ctx.moveTo(tempControlSigns.pointer[0][0], tempControlSigns.pointer[0][1])
                for (let i = 1; i < tempControlSigns.pointer.length; i++) {
                    ctx.lineTo(tempControlSigns.pointer[i][0], tempControlSigns.pointer[i][1])
                }
                ctx.stroke()
            }
            if (tempControlSigns.sign && tempControlSigns.signLoc) {
                const image = new Image()
                image.addEventListener('load', () => {
                    ctx.drawImage(
                        image,
                        tempControlSigns.signLoc[0] - state.markerSize / 2,
                        tempControlSigns.signLoc[1] - state.markerSize,
                        state.markerSize,
                        state.markerSize
                    )
                }, false)
                image.src = TrafficSigns[tempControlSigns.sign.charAt(0)][tempControlSigns.sign]
            }
        }
    }

    /*
    piirtää merkit joita ollaan muokkaamassa
    */
    const drawTempSigns = (ctx) => {
        if (tempSigns.signs) {
            for (let i = tempSigns.signs.length - 1, j = 0; i >= 0; i--, j++) {
                let sign = tempSigns.signs[j]
                const image = new Image()
                image.addEventListener('load', () => {
                    ctx.drawImage(
                        image,
                        tempSigns.signLoc[0] - state.markerSize / 2,
                        tempSigns.signLoc[1] - state.markerSize - (i * state.markerSize),
                        state.markerSize,
                        state.markerSize
                    )
                }, false)
                image.src = TrafficSigns[sign.charAt(0)][sign]
            }
        }
        if (tempSigns.postLoc) {
            const image = new Image()
            image.addEventListener('load', () => {
                ctx.translate(tempSigns.postLoc[0], tempSigns.postLoc[1])
                ctx.rotate(tempSigns.postRot)
                ctx.translate(-(tempSigns.postLoc[0]), -(tempSigns.postLoc[1]))
                ctx.drawImage(
                    image,
                    tempSigns.postLoc[0] - state.postSize / 2,
                    tempSigns.postLoc[1] - state.postSize / 2,
                    state.postSize,
                    state.postSize,
                )
                ctx.translate(tempSigns.postLoc[0], tempSigns.postLoc[1])
                ctx.rotate(-tempSigns.postRot)
                ctx.translate(-(tempSigns.postLoc[0]), -(tempSigns.postLoc[1]))
            }, false)
            image.src = TOLPAN_SUUNTA
        }
        if (tempSigns.line) {
            ctx.strokeStyle = state.lineColor
            ctx.lineWidth = state.lineWeight
            ctx.beginPath()
            ctx.moveTo(tempSigns.line[0][0], tempSigns.line[0][1])
            for (let i = 1; i < tempSigns.line.length; i++) {
                ctx.lineTo(tempSigns.line[i][0], tempSigns.line[i][1])
            }
            ctx.stroke()
        }
    }

    /*
    piirtää viivan jota ollaan muokkaamassa
    */
    const drawTempLine = (ctx) => {
        if (tempLine.line.length > 0) {
            ctx.strokeStyle = state.lineColor
            ctx.lineWidth = state.lineWeight
            ctx.beginPath()
            ctx.moveTo(tempLine.line[0][0], tempLine.line[0][1])
            for (let i = 1; i < tempLine.line.length; i++) {
                ctx.lineTo(tempLine.line[i][0], tempLine.line[i][1])
            }
            if (tempLine.tempPoint.length > 0)
                ctx.lineTo(tempLine.tempPoint[0], tempLine.tempPoint[1])
            ctx.globalAlpha = state.lineOpacity
            ctx.stroke()
        }
    }

    /*
    piirtää alueen jota ollaan muokkaamassa
    */
    const drawTempArea = (ctx) => {
        if (tempArea.points.length > 0) {
            ctx.fillStyle = state.areaColor
            ctx.strokeStyle = '#000000'
            ctx.strokeWidth = 1
            ctx.beginPath()
            ctx.moveTo(tempArea.points[0][0], tempArea.points[0][1])
            for (let i = 1; i < tempArea.points.length; i++) {
                ctx.lineTo(tempArea.points[i][0], tempArea.points[i][1])
            }
            if (tempArea.tempPoint.length > 0)
                ctx.lineTo(tempArea.tempPoint[0], tempArea.tempPoint[1])
            ctx.closePath()
            ctx.globalAlpha = state.areaOpacity
            ctx.fill()
            ctx.stroke()
        }
    } 

    /*
    piirtää tekstin jota ollaan muokkaamassa
    */
    const drawTempText = (ctx) => {
        if (tempText.position) {
            let lines = tempText.text.split(/\r?\n/)
            ctx.font = `${state.textSize}px sans-serif`
            ctx.globalAlpha = state.textOpacity

            let w = 0
            for(let i = 0; i < lines.length; i++) {
                if (ctx.measureText(lines[i]).width > w)
                    w = ctx.measureText(lines[i]).width
            }
            ctx.fillStyle = '#FF0000'
            ctx.fillRect(tempText.position[0] - 10, tempText.position[1] - 10, w + 20, lines.length * state.textSize + 20)
            ctx.fillStyle = '#FFFF00'
            ctx.fillRect(tempText.position[0] - 5, tempText.position[1] - 5, w + 10, lines.length * state.textSize + 10)


            ctx.fillStyle = state.textColor
            for(let i = 0; i < lines.length; i++) {
                ctx.fillText(lines[i], tempText.position[0], tempText.position[1] + (i + 1) * state.textSize)
            }
        }
    }

    /*
    piirtää valmiit kontrolli merkit
    */
    const drawPermControlSigns = (ctx) => {
        state.controlSigns.forEach((control) => {
            if (control.line.length > 0 && control.sign) {
                ctx.strokeStyle = state.areaColor
                ctx.lineWidth = state.lineWeight
                ctx.beginPath()
                ctx.moveTo(control.line[0][0], control.line[0][1])
                for(let i = 1; i < control.line.length; i++) {
                    ctx.lineTo(control.line[i][0], control.line[i][1])
                }
                ctx.globalAlpha = state.lineOpacity
                ctx.stroke()
    
                ctx.strokeStyle = state.lineColor
                ctx.lineWidth = state.lineWeight
                ctx.beginPath()
                ctx.moveTo(control.pointer[0][0], control.pointer[0][1])
                for (let i = 1; i < control.pointer.length; i++) {
                    ctx.lineTo(control.pointer[i][0], control.pointer[i][1])
                }
                ctx.stroke()

                const image = new Image()
                image.addEventListener('load', () => {
                    ctx.drawImage(
                        image,
                        control.signLoc[0] - state.markerSize / 2,
                        control.signLoc[1] - state.markerSize,
                        state.markerSize,
                        state.markerSize
                    )
                }, false)
                image.src = TrafficSigns[control.sign.charAt(0)][control.sign]
            }
        })
    }

    /*
    piirtää valmiit merkit
    */
    const drawPermSigns = (ctx) => {
        state.signs.forEach(signset => {
            if (signset.signs) {
                for (let i = signset.signs.length - 1, j = 0; i >= 0; i--, j++) {
                    let sign = signset.signs[j]
                    const image = new Image()
                    image.addEventListener('load', () => {
                        ctx.drawImage(
                            image,
                            signset.signLoc[0] - state.markerSize / 2,
                            signset.signLoc[1] - state.markerSize - (i * state.markerSize),
                            state.markerSize,
                            state.markerSize
                        )
                    }, false)
                    image.src = TrafficSigns[sign.charAt(0)][sign]
                }
            }
            if (signset.postLoc) {
                const image = new Image()
                image.addEventListener('load', () => {
                    ctx.translate(signset.postLoc[0], signset.postLoc[1])
                    ctx.rotate(signset.postRot)
                    ctx.translate(-(signset.postLoc[0]), -(signset.postLoc[1]))
                    ctx.drawImage(
                        image,
                        signset.postLoc[0] - state.postSize / 2,
                        signset.postLoc[1] - state.postSize / 2,
                        state.postSize,
                        state.postSize,
                    )
                    ctx.translate(signset.postLoc[0], signset.postLoc[1])
                    ctx.rotate(-signset.postRot)
                    ctx.translate(-(signset.postLoc[0]), -(signset.postLoc[1]))
                }, false)
                image.src = TOLPAN_SUUNTA
            }
            if (signset.line) {
                ctx.strokeStyle = state.lineColor
                ctx.lineWidth = state.lineWeight
                ctx.beginPath()
                ctx.moveTo(signset.line[0][0], signset.line[0][1])
                for (let i = 1; i < signset.line.length; i++) {
                    ctx.lineTo(signset.line[i][0], signset.line[i][1])
                }
                ctx.stroke()
            }
        })
    }

    /*
    piirtää valmiit viivat
    */
    const drawPermLines = (ctx) => {
        state.lines.forEach((line) => {
            ctx.strokeStyle = state.lineColor
            ctx.lineWidth = state.lineWeight
            ctx.beginPath()
            ctx.moveTo(line[0][0], line[0][1])
            for (let i = 1; i < line.length; i++) {
                ctx.lineTo(line[i][0], line[i][1])
            }
            ctx.globalAlpha = state.lineOpacity
            ctx.stroke()
        })
    }

    /*
    piirtää valmiit alueet
    */
    const drawPermAreas = (ctx) => {
        state.areas.forEach((area) => {
            ctx.fillStyle = state.areaColor
            ctx.strokeStyle = '#000000'
            ctx.strokeWidth = 1
            ctx.beginPath()
            ctx.moveTo(area[0][0], area[0][1])
            for (let i = 1; i < area.length; i++) {
                ctx.lineTo(area[i][0], area[i][1])
            }
            ctx.closePath()
            ctx.globalAlpha = state.areaOpacity
            ctx.fill()
            ctx.stroke()
        }) 
    }

    /*
    piirtää valmiit tekstit
    */
    const drawPermText = (ctx) => {
        state.texts.forEach(text => {
            let lines = text.text.split(/\r?\n/)
            ctx.font = `${state.textSize}px sans-serif`
            ctx.globalAlpha = state.textOpacity

            let w = 0
            for(let i = 0; i < lines.length; i++) {
                if (ctx.measureText(lines[i]).width > w)
                    w = ctx.measureText(lines[i]).width
            }
            ctx.fillStyle = '#FF0000'
            ctx.fillRect(text.position[0] - 10, text.position[1] - 10, w + 20, lines.length * state.textSize + 20)
            ctx.fillStyle = '#FFFF00'
            ctx.fillRect(text.position[0] - 5, text.position[1] - 5, w + 10, lines.length * state.textSize + 10)

            ctx.fillStyle = state.textColor
            for(let i = 0; i < lines.length; i++) {
                ctx.fillText(lines[i], text.position[0], text.position[1] + (i + 1) * state.textSize)
            }
        })
    }

    /*
    apufunktio joka laskee kulman suoralle joka menee origon ja (x, y) pisteen läpi
    */
    function calcAngleRadians(x, y) {
        return Math.atan2(y, x);
    }

    /*
    apufunktio, joka luo listan pisteitä jonka joihin piirretään viiva, joka menee piirrettävän merkin ohi
    */
    const calcLine = (start, end) => {
        if (!tempSigns.signLoc) return [start, end]
        let dy = end[1] - start[1]
        if (dy <= 0) return [start, end]
        let dx = end[0] - start[0]
        
        if (Math.abs(dx) < state.markerSize / 2) {
            if (dy < tempSigns.signs.length * state.markerSize) return [start, end]
            let point1 = [tempSigns.signLoc[0] - state.markerSize / 2, tempSigns.signLoc[1]]
            let point2 = [tempSigns.signLoc[0] - state.markerSize / 2, tempSigns.signLoc[1] - (tempSigns.signs.length * state.markerSize)]
            return [start, point2, point1, end]
        }

        if (dx > 0) {
            let point1 = [tempSigns.signLoc[0] - state.markerSize / 2, tempSigns.signLoc[1]]
            return [start, point1, end]
        } else {
            let point1 = [tempSigns.signLoc[0] + state.markerSize / 2, tempSigns.signLoc[1]]
            return [start, point1, end]
        }
    }

    /*
    callback joka kutsutaan silloin kun hiiren vasenta näppäintä painetaan.

    se mitä tämä funktio tekee riippuu siitä mikä state.nextAction on voimassa
    */
    const click = (e) => {
        if (state.nextAction !== ACTIONS.NO_ACTION) {
            if (state.nextAction === ACTIONS.ORIGIN_POINT) {
                setTempSigns(prev => {
                    return {
                        ...prev,
                        postLoc: [e.clientX, e.clientY],
                        postRot: 0
                    }
                })
                dispatch({ type: ACTIONS.SET_NEXT_ACTION, payload:  ACTIONS.ROTATION})
            } else if (state.nextAction === ACTIONS.ROTATION) {
                setTempSigns(prev => {
                    return {
                        ...prev,
                        postRot: -calcAngleRadians(e.clientX - prev.postLoc[0], prev.postLoc[1] - e.clientY)
                    }
                })
                dispatch({ type: ACTIONS.SET_NEXT_ACTION, payload:  ACTIONS.END_POINT})
            } else if (state.nextAction === ACTIONS.END_POINT) {
                setTempSigns(prev => {
                    return {
                        ...prev,
                        signLoc: [e.clientX, e.clientY],
                        signs: state.selectedMarkers,
                        line: calcLine(prev.postLoc, [e.clientX, e.clientY])
                    }
                })
                dispatch({ type: ACTIONS.SET_NEXT_ACTION, payload:  ACTIONS.FINALIZNG})
            } else if (state.nextAction === ACTIONS.DRAW_LINE) {
                setTempLine(prev => {
                    return {
                        line: [...prev.line, [e.clientX, e.clientY]],
                        tempPoint: []
                    }
                })
            } else if (state.nextAction === ACTIONS.DRAW_AREA) {
                setTempArea(prev => {
                    return {
                        points: [...prev.points, [e.clientX, e.clientY]],
                        tempPoint: []
                    }
                })
            } else if (state.nextAction === ACTIONS.DRAW_TEXT) {
                setTempText(prev => {
                    return {
                        text: prev.text,
                        position: [e.clientX, e.clientY]
                    }
                })
            } else if (state.nextAction === ACTIONS.DRAW_CONTROL_LINE) {
                setTempControlSigns(prev => {
                    return {
                        ...prev,
                        line: [...prev.line, [e.clientX, e.clientY]],
                        tempPoint: []
                    }
                })
            } else if (state.nextAction === ACTIONS.CONTROL_SIGN_POINT) {
                setTempControlSigns(prev => {
                    return {
                        ...prev,
                        sign: state.selectedControlSigns,
                        pointer: calcLine(prev.line[0], [e.clientX, e.clientY]),
                        signLoc: [e.clientX, e.clientY]
                    }
                })
                dispatch({type: ACTIONS.ADD_CONTROL_SIGNS, payload: tempControlSigns})
                dispatch({type: ACTIONS.SET_NEXT_ACTION, payload: ACTIONS.NO_ACTION})
                dispatch({type: ACTIONS.SET_SELECTED_CONTROL_SIGNS, payload: ''})
                setTempControlSigns({line: [], tempPoint: []})
            }
        }

        if (changeState === 'changepost') {
            setTempSigns(prev => {
                return {
                    ...prev,
                    postLoc: [e.clientX, e.clientY],
                    line: calcLine([e.clientX, e.clientY], prev.signLoc)
                }
            })
            dispatch({ type: ACTIONS.SET_NEXT_ACTION, payload:  ACTIONS.FINALIZNG})
        } else if (changeState === 'changerot') {
            setTempSigns(prev => {
                return {
                    ...prev,
                    postRot: -calcAngleRadians(e.clientX - prev.postLoc[0], prev.postLoc[1] - e.clientY)
                }
            })
            dispatch({ type: ACTIONS.SET_NEXT_ACTION, payload:  ACTIONS.FINALIZNG})
        } else if (changeState === 'changesign') {
            setTempSigns(prev => {
                return {
                    ...prev,
                    signLoc: [e.clientX, e.clientY],
                    signs: state.selectedMarkers,
                    line: calcLine(prev.postLoc, [e.clientX, e.clientY])
                }
            })
            dispatch({ type: ACTIONS.SET_NEXT_ACTION, payload:  ACTIONS.FINALIZNG})
        }
        if (changeState) setChangeState('')
        e.persist()
    }

    /*
    callback joka kutsutaan silloin kun hiiren vasenta näppäintä tuplaklikataan.
    */
    const doubleClick = (e) => {
        finishDrawing()
    }

    /*
    callback joka kutsutaan silloin kun hiirtä liikutetaan.
    */
    const mouseMove = (e) => {
        if (state.nextAction === ACTIONS.NO_ACTION) return
        if (state.nextAction === ACTIONS.ORIGIN_POINT) {
            setTempSigns(prev => {
                return {
                    ...prev,
                    postLoc: [e.clientX, e.clientY],
                    postRot: 0
                }
            })
        } else if (state.nextAction === ACTIONS.ROTATION || changeState === 'changerot') {
            setTempSigns(prev => {
                return {
                    ...prev,
                    postRot: -calcAngleRadians(e.clientX - prev.postLoc[0], prev.postLoc[1] - e.clientY)
                }
            })
        } else if (state.nextAction === ACTIONS.END_POINT || changeState === 'changesign') {
            setTempSigns(prev => {
                return {
                    ...prev,
                    signLoc: [e.clientX, e.clientY],
                    signs: state.selectedMarkers,
                    line: calcLine(prev.postLoc, [e.clientX, e.clientY])
                }
            })
        } else if (state.nextAction === ACTIONS.DRAW_LINE) {
            setTempLine(prev => {
                return {
                    line: prev.line,
                    tempPoint: [e.clientX, e.clientY]
                }
            })
        } else if (state.nextAction === ACTIONS.DRAW_AREA) {
            setTempArea(prev => {
                return {
                    points: prev.points,
                    tempPoint: [e.clientX, e.clientY]
                }
            })
        } else if (state.nextAction === ACTIONS.DRAW_CONTROL_LINE) {
            setTempControlSigns(prev => {
                return {
                    ...prev,
                    tempPoint: [e.clientX, e.clientY]
                }
            })
        } else if (state.nextAction === ACTIONS.CONTROL_SIGN_POINT) {
            setTempControlSigns(prev => {
                return {
                    ...prev,
                    sign: state.selectedControlSigns,
                    pointer: calcLine(prev.line[0], [e.clientX, e.clientY]),
                    signLoc: [e.clientX, e.clientY]
                }
            })
        }

        if (changeState === 'changepost') {
            setTempSigns(prev => {
                return {
                    ...prev,
                    postLoc: [e.clientX, e.clientY],
                    line: calcLine([e.clientX, e.clientY], prev.signLoc)
                }
            })
        }
        e.persist()
    }

    /*
    funktio joka poistaa valittuja merkkejä
    */
    const removeSelectedMarker = (id) => {
        dispatch({type: ACTIONS.REMOVE_SELECTED_MARKER, payload: id})
    }

    /*
    callback joka kutsutaan silloin state.nextAction muutetaan.

    tämän tarkoituksena on tyhjentää / resetoida muut statet mitä ei tällä hetkellä käytetä
    */
    useEffect(() => {
        if (state.nextAction === ACTIONS.NO_ACTION ||
            state.nextAction === ACTIONS.DRAW_AREA ||
            state.nextAction === ACTIONS.DRAW_LINE) {
            dispatch({type: ACTIONS.SET_SELECTED_MARKER, payload: []})
            setTempArea({points: [], tempPoint: []})
            setTempLine({line: [], tempPoint: []})
            setTempText({text: ''})
            setTempSigns({})
            setTempControlSigns({line: [], tempPoint: []})
            dispatch({type: ACTIONS.SET_SELECTED_CONTROL_SIGNS, payload: ''})
        }
        if (state.nextAction === ACTIONS.DRAW_TEXT) {
            dispatch({type: ACTIONS.SET_SELECTED_MARKER, payload: []})
            setTempArea({points: [], tempPoint: []})
            setTempLine({line: [], tempPoint: []})
            setTempSigns({})
            setTempControlSigns({line: [], tempPoint: []})
            dispatch({type: ACTIONS.SET_SELECTED_CONTROL_SIGNS, payload: ''})
        }
        if (state.nextAction === ACTIONS.DRAW_CONTROL_LINE) {
            dispatch({type: ACTIONS.SET_SELECTED_MARKER, payload: []})
            setTempArea({points: [], tempPoint: []})
            setTempLine({line: [], tempPoint: []})
            setTempText({text: ''})
            setTempSigns({})
        }
        if (state.nextAction === ACTIONS.ORIGIN_POINT) {
            setTempArea({points: [], tempPoint: []})
            setTempLine({line: [], tempPoint: []})
            setTempText({text: ''})
            setTempSigns({})
            setTempControlSigns({line: [], tempPoint: []})
            dispatch({type: ACTIONS.SET_SELECTED_CONTROL_SIGNS, payload: ''})
        }
    }, [state.nextAction, dispatch])

    /*
    luodaan jsx elementti lista valituista merkeistä
    */
    const signsJSX = state.selectedMarkers.map((ele, i) => {
        if (!ele)
            return null
        return <img
        style={{
            width: state.markerSize + 'px',
            height: state.markerSize + 'px',
        }}
        className={'hide_in_print'}
        src={TrafficSigns[ele.charAt(0)][ele]}
        alt='Selected Sign'
        onClick={() => removeSelectedMarker(i)}
        key={i}
    />
    })

    /*
    callback joka kutsutaan silloin kuin painetaan "Hyväksy" näppäintä
    */
    const acceptPost = () => {
        dispatch({type: ACTIONS.SET_SIGNS, payload: tempSigns})
        setTempSigns({})
        dispatch({type: ACTIONS.SET_NEXT_ACTION, payload: ACTIONS.NO_ACTION})
    }

    /*
    callback joka kutsutaan silloin kuin painetaan "Hylkää" näppäintä
    */
    const rejectPost = () => {
        setTempSigns({})
        dispatch({type: ACTIONS.SET_NEXT_ACTION, payload: ACTIONS.NO_ACTION})
    }

    /*
    callback joka kutsutaan silloin kuin painetaan "Vaihda toplan paikkaa" näppäintä
    */
    const changePost = () => {
        setChangeState('changepost')
    }

    /*
    callback joka kutsutaan silloin kuin painetaan "Vaihda toplan suuntaa" näppäintä
    */
    const changeRot = () => {
        setChangeState('changerot')
    }

    /*
    callback joka kutsutaan silloin kuin painetaan "Vaihda liikennemerkkien paikkaa" näppäintä
    */
    const changeSignPos = () => {
        setChangeState('changesign')
    }

    /*
    callback joka kutsutaan silloin kuin painetaan "Muokkaa tekstiä" näppäintä
    */
    const changeText = (i) => {
        setTempText(state.texts[i])
        dispatch({type: ACTIONS.DELETE_TEXT, payload: i})
        dispatch({type: ACTIONS.SET_NEXT_ACTION, payload: ACTIONS.DRAW_TEXT})
    }

    /*
    callback joka kutsutaan silloin kuin painetaan "Muokkaa tolppaa" näppäintä
    */
    const changeSign = (i) => {
        setTempSigns(state.signs[i])
        dispatch({type:ACTIONS.SET_SELECTED_MARKER, payload: state.signs[i].signs})
        dispatch({type:ACTIONS.DELETE_SIGN, payload: i})
        dispatch({type:ACTIONS.SET_NEXT_ACTION, payload: ACTIONS.FINALIZNG})
    }

    /*
    callback joka kutsutaan silloin kuin painetaan "Poista tolppa" näppäintä
    */
    const deleteSign = (i) => {
        dispatch({type:ACTIONS.DELETE_SIGN, payload: i})
    }

    /*
    callback joka kutsutaan silloin kuin painetaan "Poista viiva" näppäintä
    */
    const deleteLine = (i) => {
        dispatch({type: ACTIONS.DELETE_LINE, payload: i})
    }

    /*
    callback joka kutsutaan silloin kuin painetaan "Poista alue" näppäintä
    */
    const deleteArea = (i) => {
        dispatch({type: ACTIONS.DELETE_AREA, payload: i})
    }

    /*
    callback joka kutsutaan silloin kuin painetaan "Poista teksti" näppäintä
    */
    const deleteText = (i) => {
        dispatch({type: ACTIONS.DELETE_TEXT, payload: i})
    }

    /*
    callback joka kutsutaan silloin kuin painetaan "Poista merkki" näppäintä
    */
    const deleteControlSign = (i) => {
        dispatch({type: ACTIONS.REMOVE_CONTROL_SIGNS, payload: i})
    }

    /*
    callback joka kutsutaan silloin kuin tekstialueeseen kirjoitetaan
    */
    const changeTextArea = (e) => {
        e.persist()
        setTempText(prev => {
            if (prev.position) {
                return {
                    text: e.target.value,
                    position: prev.position
                }
            }
            return {text: e.target.value}
        })
    }

    /*
    callback joka kutsutaan silloin kuin painetaan lukkoa
    */
    const lock = () => {
        dispatch({type: ACTIONS.SET_MAP_LOCK})
    }

    /*
    callback joka kutsutaan silloin kuin painetaan "Lopeta piirtäminen" yms. näppäimiä
    */
    const finishDrawing = () => {
        if (state.nextAction === ACTIONS.DRAW_CONTROL_LINE) {
            dispatch({type: ACTIONS.SET_NEXT_ACTION, payload: ACTIONS.CONTROL_SIGN_POINT})
            return
        }
        dispatch({type: ACTIONS.SET_NEXT_ACTION, payload: ACTIONS.NO_ACTION})
        if (state.nextAction === ACTIONS.DRAW_LINE) {
            if (tempLine.line.length > 0) {
                dispatch({type: ACTIONS.ADD_LINE, payload: tempLine.line})
            }
            setTempLine({line: [], tempPoint: []})
        } else if (state.nextAction === ACTIONS.DRAW_AREA) {
            if (tempArea.points.length > 0) {
                dispatch({type: ACTIONS.ADD_AREA, payload: tempArea.points})
            }
            setTempArea({points: [], tempPoint: []})
        } else if (state.nextAction === ACTIONS.DRAW_TEXT) {
            if (tempText) {
                dispatch({type: ACTIONS.ADD_TEXT, payload: tempText})
            }
            setTempText({text: ''})
        } 
    }

    return (
        <>
            <div id={'control'} className={'hide_in_print'}>
                {signsJSX && signsJSX}
                {state.selectedControlSigns &&
                <img
                    style={{
                        width: state.markerSize + 'px',
                        height: state.markerSize + 'px',
                    }}
                    className={'hide_in_print'}
                    src={TrafficSigns[state.selectedControlSigns.charAt(0)][state.selectedControlSigns]}
                    alt='Selected Sign'
                    onClick={() => dispatch({type: ACTIONS.SET_SELECTED_CONTROL_SIGNS, payload: ''})}
                />}
                {!state.mapLock && 'Lukitse kartta ennen liikennemerkkien asettamista'}
                {state.nextAction === ACTIONS.NO_ACTION && state.mapLock && 'Valitse liikennemerkit tai piirtotyökalu'}
                {state.nextAction === ACTIONS.ORIGIN_POINT && state.mapLock && 'Valitse liikennemerkkien sijainti'}
                {state.nextAction === ACTIONS.ROTATION && state.mapLock && 'Valitse liikennemerkkien näyttösuunta'}
                {state.nextAction === ACTIONS.END_POINT && state.mapLock && 'Valitse mihin liikennemerkit piirretään kartalla'}
                {state.nextAction === ACTIONS.FINALIZNG && state.mapLock && 
                <>
                    <button onClick={changePost}>Vaihda tolpan paikkaa</button>
                    <button onClick={changeRot}>Vaihda tolpan suuntaa</button>
                    <button onClick={changeSignPos}>Vaihda liikennemerkkien paikkaa</button>
                    <br/>
                    <button onClick={acceptPost}>Hyväksy</button>
                    <button onClick={rejectPost}>Hylkää</button>
                </>}
                {state.nextAction === ACTIONS.DRAW_LINE && state.mapLock && 
                <>
                    <span>Valitse pisteet joiden väliin piirretään viiva</span>
                    <button onClick={finishDrawing}>Lopeta piirtäminen</button>
                </>}
                {state.nextAction === ACTIONS.DRAW_AREA && state.mapLock && 
                <>
                    <span>Valitse pisteet joiden väliin piirretään alue</span>
                    <button onClick={finishDrawing}>Lopeta piirtäminen</button>
                </>}
                {state.nextAction === ACTIONS.DRAW_TEXT && state.mapLock && 
                <>
                    <span>Valitse paikka minne teksti kirjoitetaan</span>
                    <textarea id='textarea' value={tempText.text} onChange={changeTextArea} rows={5} />
                    {tempText.position &&
                    <button onClick={finishDrawing}>Lopeta kirjoittaminen</button>}
                </>}
                {state.nextAction === ACTIONS.DRAW_CONTROL_LINE && state.mapLock &&
                <>
                    <span>Valitse pisteet joiden väliin laitetaan liikenneohjausmerkit</span>
                    <button onClick={finishDrawing}>Lopeta merkkaaminen</button>
                </>}
                {state.nextAction === ACTIONS.CONTROL_SIGN_POINT && state.mapLock && 'Valitse mihin merkki piirretään'}
            </div>

            <div id='finished-parts' className={'hide_in_print'}>
                <button className={'hidebtn'} onClick={() => {setHideFinished(prev => !prev)}}>{hideFinished ? 'Näytä' : 'Piilota'}</button>
                {!hideFinished && <div>
                    {state.signs.length > 0 &&
                    <div className={'hide_in_print'}>
                        <h4>Tolpat</h4>
                        {state.signs.map((set, i) => {
                            let signNames = ''
                            set.signs.forEach((s) => signNames += s + ' ')
                            return <div key={i}>
                                <div>Tolppa numero {i}, joka sisältää merkit {signNames}</div>
                                <button onClick={() => {deleteSign(i)}}>Poista tolppa</button>
                                <button onClick={() => {changeSign(i)}}>Muokkaa tolppaa</button>
                                {state.signs.length !== i+1 && <hr/>}
                            </div>
                        })}
                        <br/>
                    </div>}
                    {state.lines.length > 0 &&
                    <div className={'hide_in_print'}>
                        <h4>Viivat</h4>
                        {state.lines.map((line, i) => {
                            return <div key={i}>
                                <div>Viiva numero {i}</div>
                                <button onClick={() => {deleteLine(i)}}>Poista viiva</button>
                                {state.lines.length !== i+1 && <hr/>}
                            </div>
                        })}
                        <br/>
                    </div>}
                    {state.areas.length > 0 && 
                    <div className={'hide_in_print'}>
                        <h4>Alueet</h4>
                        {state.areas.map((area, i) => {
                            return <div key={i}>
                                <div>Alue numero {i}</div>
                                <button onClick={() => deleteArea(i)}>Poista alue</button>
                                {state.areas.length !== i+1 && <hr/>}
                            </div>
                        })}
                        <br/>
                    </div>}
                    {state.texts.length > 0 &&
                    <div className={'hide_in_print'}>
                        <h4>Tekstit</h4>
                        {state.texts.map((text, i) => {
                            return <div key={i}>
                                <div>Teksti: {text.text}</div>
                                <button onClick={() => deleteText(i)}>Poista teksti</button>
                                <button onClick={() => {changeText(i)}}>Muokkaa tekstiä</button>
                                {state.texts.length !== i+1 && <hr/>}
                            </div>
                        })}    
                    </div>}
                    {state.controlSigns.length > 0 &&
                    <div>
                        <h4>Liikenteenohjaus merkit</h4>
                        {state.controlSigns.map((control, i) => {
                            return <div key={i}>
                                <div>Liikenteenohjaus merkki: {control.sign}</div>
                                <button onClick={() => deleteControlSign(i)}>Poista merkki</button>
                                {state.controlSigns.length !== i+1 && <hr/>}
                            </div>
                        })}
                    </div>}
                </div>}
            </div>
            {state.mapLock && 
                <div id={'canvas'} onClick={click} onMouseMove={mouseMove} onDoubleClick={doubleClick}>
                    <canvas ref={ref}></canvas>
                </div>
            }
            <div id='lock'>
                {state.mapLock ? 
                <img className={'hide_in_print'} onClick={lock} src={LUKKO} alt={'lukittu lukko'} width={64} height={64} /> : 
                <img className={'hide_in_print'} onClick={lock} src={LUKKO_AUKI} alt={'avattu lukko'} width={64} height={64} />}
            </div>
        </>
    )
}

export default Control
