import React from 'react'

/* 
konteksti

pitää tallessa sivun tilan.
*/
export const initialState = {
    markerSize: 64,
    postSize: 32,
    selectedMarkers: [],
    nextAction: 'noaction',
    lineColor: '#FF0000',
    lineOpacity: 1,
    lineWeight: 2,
    mapLock: false,
    signs: [],
    lines: [],
    areas: [],
    areaColor: '#FF00FF',
    areaOpacity: 0.7,
    texts: [],
    textColor: '#000000',
    textOpacity: 1,
    textSize: 25,
    selectedControlSigns: '',
    controlSigns: [],
}

const OptionsContext = React.createContext(initialState)
export default OptionsContext