import React, { useReducer } from 'react';
import './App.css';
import MapContainer from './MapContainer';
import Reducer from './Reducer';
import SideBar from './SideBar'
import Control from './Control'
import Context, { initialState } from './OptionsContext'

function App() {
  const [state, dispatch] = useReducer(Reducer, initialState)
  return (
    <Context.Provider value={ {state, dispatch} }>
      <SideBar />
      <MapContainer />
      <Control />
    </Context.Provider>
  );
}

export default App;
