import React from 'react'
/* 
kaikkien liikennemerkkien osoitteet
*/
const data = {
    A: {
        A4: process.env.PUBLIC_URL + '/Liikennemerkit/Varoitusmerkit/A4_Kapeneva_tie.svg',
        A9: process.env.PUBLIC_URL + '/Liikennemerkit/Varoitusmerkit/A9_Epätasainen_tie.svg',
        A11: process.env.PUBLIC_URL + '/Liikennemerkit/Varoitusmerkit/A11_Tietyö.svg',
        A21: process.env.PUBLIC_URL + '/Liikennemerkit/Varoitusmerkit/A21_Tienristeys.svg',
        A22_3: process.env.PUBLIC_URL + '/Liikennemerkit/Varoitusmerkit/A22.3_Sivutien_risteys.svg',
        A23: process.env.PUBLIC_URL + '/Liikennemerkit/Varoitusmerkit/A23_Liikennevalot.svg',
        A24: process.env.PUBLIC_URL + '/Liikennemerkit/Varoitusmerkit/A24_Liikenneympyrä.svg',
    },
    B: {
        B3: process.env.PUBLIC_URL + '/Liikennemerkit/Etuajo-oikeus ja väistämismerkit/B3_Etuajo-oikeus_kohdattaessa.svg',
        B4: process.env.PUBLIC_URL + '/Liikennemerkit/Etuajo-oikeus ja väistämismerkit/B4_Väistamisvelvollisuus_kohdattaessa.svg',
        B5: process.env.PUBLIC_URL + '/Liikennemerkit/Etuajo-oikeus ja väistämismerkit/B5_Väistämisvelvollisuus_risteyksessä.svg',
        B6: process.env.PUBLIC_URL + '/Liikennemerkit/Etuajo-oikeus ja väistämismerkit/B6_Pakollinen_pysäyttäminen.svg',
        B7: process.env.PUBLIC_URL + '/Liikennemerkit/Etuajo-oikeus ja väistämismerkit/Finland_road_sign_B7.svg'
    },
    C: {
        C1: process.env.PUBLIC_URL + '/Liikennemerkit/Kielto- ja rajoitusmerkit/C1_Ajoneuvolla_ajo_kielletty.svg',
        C2: process.env.PUBLIC_URL + '/Liikennemerkit/Kielto- ja rajoitusmerkit/C2_Moottorikäyttöisellä_ajoneuvolla_ajo_kielletty.svg',
        C11: process.env.PUBLIC_URL + '/Liikennemerkit/Kielto- ja rajoitusmerkit/C11_Polkupyörällä_ajo_kielletty.svg',
        C13: process.env.PUBLIC_URL + '/Liikennemerkit/Kielto- ja rajoitusmerkit/C13_Jalankulku_kielletty.svg',
        C14: process.env.PUBLIC_URL + '/Liikennemerkit/Kielto- ja rajoitusmerkit/C14_Jalankulku_ja_polkupyorällä_ajo_kielletty.svg',
        C17: process.env.PUBLIC_URL + '/Liikennemerkit/Kielto- ja rajoitusmerkit/C17_Kielletty_ajosuunta.svg',
        C18: process.env.PUBLIC_URL + '/Liikennemerkit/Kielto- ja rajoitusmerkit/C18_Vasemmalle_kääntyminen_kielletty.svg',
        C19: process.env.PUBLIC_URL + '/Liikennemerkit/Kielto- ja rajoitusmerkit/C19_Oikealle_kääntyminen_kielletty.svg',
        C32: process.env.PUBLIC_URL + '/Liikennemerkit/Kielto- ja rajoitusmerkit/C32_Nopeusrajoitus.svg',
        C32_30: process.env.PUBLIC_URL + '/Liikennemerkit/Kielto- ja rajoitusmerkit/C32_30_Nopeusrajoitus.svg',
        C32_40: process.env.PUBLIC_URL + '/Liikennemerkit/Kielto- ja rajoitusmerkit/C32_40_Nopeusrajoitus.svg',
        C33: process.env.PUBLIC_URL + '/Liikennemerkit/Kielto- ja rajoitusmerkit/C33_Nopeusrajoitus_päättyy.svg',
        C37: process.env.PUBLIC_URL + '/Liikennemerkit/Kielto- ja rajoitusmerkit/C37_Pysäyttäminen_kielletty.svg',
        C38: process.env.PUBLIC_URL + '/Liikennemerkit/Kielto- ja rajoitusmerkit/C38_Pysäköinti_kielletty.svg',
    },
    D: {
        D3_1: process.env.PUBLIC_URL + '/Liikennemerkit/Määräysmerkit/D3.1_Liikenteenjakaja.svg',
        D3_2: process.env.PUBLIC_URL + '/Liikennemerkit/Määräysmerkit/D3.2_Liikenteenjakaja.svg',
    },
    E: {
        E1: process.env.PUBLIC_URL + '/Liikennemerkit/Sääntömerkit/E1_Suojatie.svg',
    },
    F: {
        F5: process.env.PUBLIC_URL + '/Liikennemerkit/Opastusmerkit/F5_Kiertotieopastus.svg',
        F6: process.env.PUBLIC_URL + '/Liikennemerkit/Opastusmerkit/F6_Ajoreittiopastus.svg',
        F15: process.env.PUBLIC_URL + '/Liikennemerkit/Opastusmerkit/F15_Kiertotien_viitta.svg',
        F24_1: process.env.PUBLIC_URL + '/Liikennemerkit/Opastusmerkit/F24.1_Umpitie.svg',
    },
    H: {
        H1: process.env.PUBLIC_URL + '/Liikennemerkit/Lisäkilvet/H1_Kohde_risteävässä_suunnassa.svg',
        H1_2: process.env.PUBLIC_URL + '/Liikennemerkit/Lisäkilvet/H1_2_Kohde_risteävässä_suunnassa_vasen.svg',
        H25: process.env.PUBLIC_URL + '/Liikennemerkit/Lisäkilvet/H25_Huoltoajo_sallittu.svg',
    },
    I: {
        I1: process.env.PUBLIC_URL + '/Liikennemerkit/Muut likkenneohjaus merkit/I1_Sulkupuomi.svg',
        I2_1: process.env.PUBLIC_URL + '/Liikennemerkit/Muut likkenneohjaus merkit/I2.1_Sulkuaita.svg',
        I3_3: process.env.PUBLIC_URL + '/Liikennemerkit/Muut likkenneohjaus merkit/I3.3_Sulkupylväs.svg',
        I4: process.env.PUBLIC_URL + '/Liikennemerkit/Muut likkenneohjaus merkit/I4_Sulkukartio.svg',
    },
}

export const TrafficSignURLs = React.createContext(data)