import React from 'react'

export const SideBarSegment = ({children, name, open, setOpen, value}) => {
    const openUP = () => {
        if (open !== value) {
            setOpen(value) 
        } else
            setOpen('')
    } 
    
    return (
        <div className={'sidebar-segment'} >
            <div className={'segment-title'} onClick={openUP}>{name}</div>
            {open === value && children}
        </div>
    )
}
